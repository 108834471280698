import React from 'react';
import useGlobal from "../Store/globalStore";
import './Requirements.css';

/* eslint-disable jsx-a11y/anchor-is-valid */
const Requirements = () => {
    const [globalState, globalActions] = useGlobal()
    const isAuthenticated = globalState.auth && globalState.auth.isAuthenticated()
    const geoOk = globalState.geoLocation.status === 'success'
    const locationSent = globalState.locationSent;
    return (
        <div className = " grid requirements">
        <div className = " left-half">
        {
            !locationSent && !geoOk && (
                <div onClick= {() => globalActions.retrieveLocationIfNeeded()}>
                <button className =" icon-location">
                </button>
                </div>
            )
        }
        {
            !(!locationSent && !geoOk) && (
                <div onClick= {() => globalActions.retrieveLocationIfNeeded()}>
                <button className =" icon-location fullfilled" >
                </button>
                </div>
            )
        }
        </div>
        <div className = " right-half">
        {
            !(locationSent || isAuthenticated) && (
                <div onClick={() => globalActions.login()}> 
                <button className =" icon-adult" >
                </button>
                </div>
            )
        }
        {
            (locationSent || isAuthenticated) && (
                <div> 
                <button className =" icon-adult fullfilled" >
                </button>
                </div>
            )
        }
        </div>
        <div className="full-content why-link">
            <a onClick= {() => globalActions.showWhyPopUp()}>¿Por qué necesitamos tu ubicación e identitidad?</a>
        </div>
    </div>
    )
}

export default Requirements;