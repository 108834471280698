import history from '../history';
import auth0 from 'auth0-js';
import { AUTH_CONFIG } from './auth0-variables';

export default class Auth {
    accessToken;
    idToken;
    expiresAt;
    userId;
    
    auth0 = new auth0.WebAuth({
        domain: AUTH_CONFIG.domain,
        clientID: AUTH_CONFIG.clientId,
        redirectUri: AUTH_CONFIG.callbackUrl,
        responseType: 'token id_token',
        scope: 'openid'
    });
    
    login() {
        this.auth0.authorize();
    }
    
    logout() {
      // Remove tokens and expiry time
      this.accessToken = null;
      this.idToken = null;
      this.expiresAt = 0;

      // Remove isLoggedIn flag from localStorage
      localStorage.removeItem('isLoggedIn');
      this.auth0.logout({
        return_to: window.location.origin
      });
    }
    
    handleAuthentication() {
        this.auth0.parseHash((err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                history.replace('/');
                console.log(err);
                alert(`Error: ${err.error}. Check the console for further details.`);
            }
        });
    }
    getUserId() {
        return this.userId;
    }
    
    getAccessToken() {
        return this.accessToken;
    }
    
    getIdToken() {
        return this.idToken;
    }
    
    setSession(authResult) {
        localStorage.setItem('isLoggedIn', 'true');
        let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();
        this.accessToken = authResult.accessToken;
        this.idToken = authResult.idToken;
        this.userId = authResult.idTokenPayload.sub;
        this.expiresAt = expiresAt;
        localStorage.setItem('auth_token', this.idToken)
        localStorage.setItem('auth_uid', this.userId)
        localStorage.setItem('auth_expiresAt', this.expiresAt)
        history.replace('/');
    }
    
    renewSession() {
        this.auth0.checkSession({}, (err, authResult) => {
            if (authResult && authResult.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                this.logout();
                console.log(err);
                alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
            }
        });
    }
    
    isAuthenticated() {
        let expiresAt = this.expiresAt;
        return new Date().getTime() < expiresAt;
    }
}

export const handleAuthentication = store => {
    const auth = store.auth_obj || new Auth()
    auth.handleAuthentication()
    store.setState({auth_obj: auth})
    store.setState(
        {
            auth: {
                token: auth.getIdToken(),
                uid: auth.getUserId(),
                isAuthenticated: () => new Date().getTime() < auth.expiresAt,
            }
        })
}

export const login = store => {
    const auth = store.auth_obj || new Auth()
    return auth.login()
}

