import { writeData } from '../Firebase/Database'
import { retrieveLocationIfNeeded, locationEnabled } from '../Geo/Location';
import { handleAuthentication, login } from '../Auth/Auth';

const showWhyPopUp = store => {
    store.setState({showWhy: true})
}

const hideWhyPopUp = store => {
    store.setState({showWhy: false})
}

const setEvent = (store, eventId, eventTitle) => {
    store.setState({eventId: eventId})
    store.setState({eventTitle: eventTitle})
    localStorage.setItem('eventId', eventId)
    localStorage.setItem('eventTitle', eventTitle)
}

export {
    writeData,
    retrieveLocationIfNeeded,
    locationEnabled,
    handleAuthentication,
    login,
    showWhyPopUp,
    hideWhyPopUp,
    setEvent,
}
