import React from 'react';
import useGlobal from "../Store/globalStore";
import './Event.css';
import {useNewTimer} from '../Aux/Timer.js'

const diezHoras = 10 * 3600

const Event = (props) => {
    const [globalState, globalActions] = useGlobal()
    const timer = useNewTimer(new Date())
    
    const isAuthenticated = globalState.auth && globalState.auth.isAuthenticated()
    const isLocated = globalState.geoLocation.status === 'success'
    
    const writeData = () => {
        globalActions.setEvent(props.data.id, props.data.title)
        return globalActions.writeData()
    }
    const timeDifferenceInMinutes = ((timer.getTime() - props.data.startTime) / (60 * 1000))
    const started = timeDifferenceInMinutes > -1
    const timeStartsOrStarted = started ? 'Empezó hace:' : 'Empieza en:'
    const absTimeDifference = Math.abs(timeDifferenceInMinutes)
    const showTime = absTimeDifference < diezHoras
    const timeDifferenceToShow = '' + Math.floor(absTimeDifference / 60) + 'h:' 
                                    + Math.floor(absTimeDifference - Math.floor(absTimeDifference / 60) * 60) + 'm'
    
    const isEnabled = (isAuthenticated && isLocated && !globalState.locationSent && started)
    return (
        <div>
        <button
        disabled= {!isEnabled}
        className="event-btn "
        onClick={writeData}>
        <div className="grid no-margin ">
        <div className= "leftist ">
        <img className= "event-logo " src={props.data.image}  alt= "Unknown event icon"/>
        </div>
        <div className="rigthist ">
        <h3>
        {props.data.title}
        </h3>
        <span className="description ">
        {props.data.descrpition}
        </span>
        </div>
        {
            showTime && (
                <div className="real-rigth-margin timer ">
                <p>
                <span>{timeStartsOrStarted}</span>
                </p>
                <span>{timeDifferenceToShow}</span>
                </div>
            )
        }
        
        </div>
        </button>
        </div>
    )
}

export default Event;
