import React from 'react';
import loading from './loading.svg';
import useGlobal from '../Store/globalStore';

const Callback = (props) => {
    const [, globalActions] = useGlobal()
    const handleAuthentication = (nextState, replace) => {
        if (/access_token|id_token|error/.test(nextState.location.hash)) {
            globalActions.handleAuthentication()
        }
    }
    handleAuthentication(props)
    return (
        <div>
        <img src={loading} alt="loading"/>
        </div>    
    )
}

export default Callback;