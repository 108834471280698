import React from 'react'
import useGlobal from "../Store/globalStore";
import './Done.css';

const Done = () => {
    const [globalState, globalActions] = useGlobal()
    return (
        <div className= "big-popup done-screen ">
        <div className="grid centered ">
        <div className="real-full-content ">
        <div>
        <h1>
        +1
        </h1>
        <p>
        <span>¡Gracias por confirmar tu presencia en </span><span className= "bold"> {globalState.eventTitle}</span>!
        </p>
        <p>
        Durante la duración del acto iremos publicando datos de asistencia en Twitter
        </p>
        <a className = "left" href="https://twitter.com/EspanaWehere">
            <img className= 'twitter-icon' src = "twitter.png" alt = "twitter icon"/>
        </a>
        <p>
        No te olvides de compartir <span className= "bold">weHere</span> con otros asistentes para que ellos también cuenten!
        </p>
        <a className = "rigth" href="whatsapp://send?text=Entra y haz que tu presencia cuente: weHere.es" 
        data-action="share/whatsapp/share">
            <img className= 'twitter-icon' src = "whatsapp.png" alt = "whatsapp icon"/>
        </a>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Done;
