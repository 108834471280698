import React from 'react';
import { Route, Router } from 'react-router-dom';
import App from './App';
import Callback from './Callback/Callback';
import history from './history';
import firebase from 'firebase/app';
import { FIREBASE_CONFIG } from './Firebase/config';

firebase.initializeApp(FIREBASE_CONFIG);

export const makeMainRoutes = () => {
  return (
    <Router history={history} component={App}>
      <div>
        <Route path="/" render={(props) => 
              <App {...props} />} />
        <Route path="/callback" render={(props) =>
              <Callback {...props} />}/>
      </div>
    </Router>
  );
}
