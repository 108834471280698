const fiveHours = 5 * 3600 * 1000
let tempToday = new Date()
tempToday.setHours(0,0,1) 
const today = tempToday.getTime()
const getDate = (year, month, day, hour) => new Date(year, month-1, day, hour, 0, 0, 0).getTime()

const areaEspana = 
{
    topLeft: {
        latitude: 43.982766,
        longitude: -9.996690,
    },
    bottomRight: {
        latitude: 34.299046,
        longitude: 3.879352,
    }
}
const areaWorld = 
{
    topLeft: {
        latitude: 90,
        longitude: -20,
    },
    bottomRight: {
        latitude: 0,
        longitude: 50,
    }
}
const areaFrance = 
{
    topLeft: {
        latitude: 51.583946,
        longitude: -6.912370,
    },
    bottomRight: {
        latitude: 43.386351,
        longitude: 9.923938,
    }
}
const ava = {
    topLeft: {
        latitude: 40.420129,
        longitude: -3.698006,
    },
    bottomRight: {
        latitude:40.419504,
        longitude: -3.696721,
    }
}

export const eventData = [
    {
        id:"ev1",
        title: "Vox - La Coruña",
        descrpition: "Palexco Muelle de Transatlánticos, La Coruña",
        image: "events/logo_vox.png",
        area: {
            topLeft: {
                latitude: 43.370107,
                longitude: -8.405534,
            },
            bottomRight: {
                latitude: 43.364866,
                longitude: -8.396651,
            }
        },
        startTime: getDate(2019, 4, 22, 18),
        duration: fiveHours,
    },
    {
        id:"ev2",
        title: "Vox - Plaza de Colón",
        descrpition: "Gran acto cierre de campaña con Santiago Abascal",
        image: "events/logo_vox.png",
        area: {
            topLeft: {
                latitude: 40.428858,
                longitude: -3.694118,
            },
            bottomRight: {
                latitude: 40.418428,
                longitude: -3.683587,
            }
        },
        startTime: getDate(2019, 4, 26, 20),
        duration: fiveHours,
    },
    {
        id:"ev3",
        title: "Flipper",
        descrpition: "Evento en Francia",
        image: "events/logo_vox.png",
        area: areaFrance,
        startTime: new Date().getTime() + 2 *3600 *1000,
        duration: fiveHours,
    },
    {
        id:"ev5",
        title: "Hoy a las 12",
        descrpition: "Test Event",
        image: "events/unknown.svg",
        area: areaEspana,
        startTime: today + 12 * 3600 * 1000,
        duration: fiveHours,
    },
    {
        id:"ev6",
        title: "Hoy a las 18",
        descrpition: "Test Event",
        image: "events/unknown.svg",
        area: areaEspana,
        startTime: today + 18 * 3600 * 1000,
        duration: fiveHours,
    },
    {
        id:"ev7",
        title: "Hoy a las 21",
        descrpition: "Test Event",
        image: "events/unknown.svg",
        area: areaEspana,
        startTime: today + 21 * 3600 * 1000,
        duration: fiveHours,
    },
    {
        id:"ev8",
        title: "Ada a las 11",
        descrpition: "Test Event",
        image: "events/unknown.svg",
        area: ava,
        startTime: today + 11 * 3600 * 1000,
        duration: fiveHours * 3,
    },
    {
        id:"ev0",
        title: "Otro acto",
        descrpition: "Evento no registrado en la plataforma",
        image: "events/unknown.svg",
        area: areaWorld,
        startTime: 0,
        duration: Infinity,
    },
]