import firebase from 'firebase/app';
import 'firebase/database'; 

const mod = (text) => text.substr(0, 4).concat("_").concat(text.substr(4))
const addZeroes = (number, numOfPositions) => '0'.repeat(numOfPositions - String(number).length) + number
const getFullDate = () => {
    const d = new Date()
    return '' + d.getUTCFullYear() + addZeroes(d.getUTCMonth(), 2) + addZeroes(d.getUTCDate(), 2)
}

const writeUserData = (eventId , uid, timeStamp, userToken, longitude, latitude) => {
    eventId = eventId || 'ev0'
    const locData = {
        uid: uid,
        timeStamp: timeStamp,
        userToken: userToken,
        longitude: longitude,
        latitude : latitude,
        eventId: eventId,
    }
    const path = eventId !== 'ev0' 
        ? '/registrations/' + eventId + '/' + uid
        : '/registrations/' + eventId + '/' + getFullDate() + '/' + uid
    firebase.database().ref(path).set(locData)
}

export const writeData = store => {
    const globalState = store.state
    if (!globalState.locationSent) {
        writeUserData(
            globalState.eventId,
            (globalState.auth_obj && globalState.auth_obj.getUserId()) || globalState.auth.uid,
            firebase.database.ServerValue.TIMESTAMP,
            mod((globalState.auth_obj && globalState.auth_obj.getIdToken()) || globalState.auth.token),
            globalState.geoLocation.longitude,
            globalState.geoLocation.latitude,
        )
        localStorage.setItem('lastLocationSent', (new Date()).getTime());
        store.setState({locationSent: true})
    }
}

//TODO
/*
const fetchTotal = firebase.database().ref('posts/' + postId + '/starCount')
    fetchTotal.on('value', snapshot) {
        updateStarCount(postElement, snapshot.val());
    })
}

export const updateTotal = store => {
    const globalState = store.state
    if (!globalState.locationSent) {
        writeUserData(
            globalState.eventId,
            (globalState.auth_obj && globalState.auth_obj.getUserId()) || globalState.auth.uid,
            firebase.database.ServerValue.TIMESTAMP,
            mod((globalState.auth_obj && globalState.auth_obj.getIdToken()) || globalState.auth.token),
            globalState.geoLocation.longitude,
            globalState.geoLocation.latitude,
        )
        localStorage.setItem('lastLocationSent', (new Date()).getTime());
        store.setState({locationSent: true})
    }
}
*/

