import React from 'react';
import useGlobal from "./Store/globalStore";
import Requirements from './Home/Requirements';
import EventList from './Home/EventList';
import Done from './Home/Done';
import Why from './Info/Why';
import './App.css';

const App = () => {
    const [globalState, ] = useGlobal()
    const enabled = globalState.isMobile || globalState.devMode
    return (
        <div>
        <div className="grid">
        <div className="title-bar" >
        <img src="icon.png" height="60px" alt= "WeHere logo"/>
        <h2>
        WeHere
        </h2>
        <span>
        Haz que cuente
        </span>
        </div>
        </div>
        {
        !enabled && (
            <div className='full-content'>
            <h2>
            WeHere sólo puede usarse en dispositivos móviles
            </h2>
            </div>
            )
        }
        {
            enabled && (<Requirements/>)
        }
        {
            enabled && (<EventList/>)
        }
        {
            enabled && globalState.showWhy && (<Why/>)
        }
        {
            enabled && globalState.locationSent && (<Done/>)
        }
        
        </div>
            )
        }
        
export default App;