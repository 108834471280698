import React from 'react'
import useGlobal from "../Store/globalStore";

const Why = (props) => {
    const [, globalActions] = useGlobal()
    
    return (
        <div className="big-popup" onClick= {() => globalActions.hideWhyPopUp()} >
        <div className="grid">
        <div className="full-content overlay">
        <p className=" exit">
        x
        </p>
        <p>
            WeHere consulta tu identidad en Google o Facebook para contabilizar 
            de forma fiable los asistentes al acto.
        </p>
        <p>
            Usamos tu ubicación para buscar actos registrados cercanos
            y para certificar tu asistencia.
        </p>
        <p>
            Únicamente almacenamos tu identificador de Google o Facebook y tu 
            ubicación en el momento en que usaste nuesta app. 
        </p>
        <p>
            No guardamos tu email,
            nombre ni ningún otro dato personal.
        </p>
        <p>
            La única información que compartimos con los organizadores de los  
            eventos es el número total de asistentes, ningún detalle adicional.
        </p>
        <div className = " email">
        <a href="mailto:info@wehere.es">
            info@wehere.es
        </a>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Why;
