const { task } = require('folktale/concurrency/task');

const twoMinutes = 2 * 60 * 1000

const getLocation = () => task(resolver => {
    navigator.geolocation.getCurrentPosition(
        (position) => 
        {resolver.resolve({
            status: 'success',
            longitude: position.coords.longitude,
            latitude: position.coords.latitude,
        })},
        () => {resolver.resolve({status: 'fail',})},
        {
            enableHighAccuracy: true,
            timeout: 6000,
        },
    );
})

export const retrieveLocationIfNeeded = store => {
    if (
        store.state.geoLocation.status !== 'success'
        || (localStorage.getItem('geoLocationExpires') 
        && (new Date().getTime() > Number(localStorage.getItem('geoLocationExpires'))))
    ) {
        // eslint-disable-next-line array-callback-return
        getLocation().run().future().map(function(value) {
            store.setState({geoLocation: value})
            localStorage.setItem('geoLocationLongitude', value.longitude)
            localStorage.setItem('geoLocationLatitude', value.latitude)
            localStorage.setItem('geoLocationExpires', new Date().getTime() + twoMinutes)
        })
    }
}

export const locationEnabled = () => navigator.geolocation
