import React from 'react'
import useGlobal from "../Store/globalStore";
import Event from "./Event";
import {eventData} from "./eventData.js"

const dateMargin = 2 * 3600 * 1000

const between = (a, limit1, limit2) => {
    if (limit1 > limit2) {
        return (a<limit1 && a>limit2)
    }
    return (a>limit1 && a<limit2)
}

const dateFilter = (event) => between(new Date().getTime(), event.startTime - dateMargin, event.startTime + event.duration)

const EventList = () => {
    const [globalState, ] = useGlobal()
    const isAuthenticated = globalState.auth && globalState.auth.isAuthenticated()
    
    const isLocated = globalState.geoLocation.status === 'success'
    const isEnabled = (isAuthenticated && isLocated && !globalState.locationSent)
    const locatedClass = isLocated ? " done" : ""
    const authenticatedClass = isAuthenticated ? " done" : ""
    
    const locationFilter = (event) => {
        return (between(globalState.geoLocation.latitude, event.area.topLeft.latitude, event.area.bottomRight.latitude)
        && between(globalState.geoLocation.longitude, event.area.topLeft.longitude, event.area.bottomRight.longitude))
    }
    const eventItems = eventData
        .filter((ev) => dateFilter(ev) && locationFilter(ev))
        .map((ev) => <Event data = {ev} key = {ev.id}/>)

    return (
        <div className="grid event-list">
        { !globalState.locationSent && !isEnabled && (
            <div className=" title" >
            <span className= {locatedClass}>Activa tu localización para ver eventos cercanos</span> 
            <span> </span>
            <span className= {authenticatedClass}> y autentícate</span> para 
            poder confirmar tu asistencia
            </div>
        )
        }
        { !globalState.locationSent && isEnabled && (
            <div className=" title" >
            Confirma tu presencia
            </div>
        )
        }
        { globalState.locationSent && (
            <div className=" title" >
            ¡Gracias por hacer que tu presencia cuente!
            </div>
        )
        }
        <div className=" full-content">{eventItems}</div>
        </div>
    )
}

export default EventList;
